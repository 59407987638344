import React, { memo } from 'react';

import { useSearch } from '../../hooks/context';
import VisFacetedSearchDesktop from '../../components/intervention/faceted-search/vis-faceted-search.desktop';
import { LAYOUTS } from '../../constants';
import { withComponentHandler } from '../../hocs/with-component-handler';

const LAYOUT_TYPES = [LAYOUTS.TYPE_GRID, LAYOUTS.TYPE_STACK];

const FacetedSearchDesktopContainer = () => {
  const { new_faceted_search: newFacetedSearch, layout_options } = useSearch();

  const isAllowedLayout = LAYOUT_TYPES.includes(layout_options?.current);

  if (!isAllowedLayout || !newFacetedSearch?.faceted_config) {
    return null;
  }

  return <VisFacetedSearchDesktop />;
};

const MemoFacetedSearchDesktopContainer = memo(FacetedSearchDesktopContainer);

export default withComponentHandler(MemoFacetedSearchDesktopContainer, { componentName: 'Faceted-desktop' });
