/**
 * Module dependencies
 */
import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';
import { LazyHydrate as Hydrator } from 'nordic/lazy';

import Search from './main';
import SideBar from '../../../../../components-v2/sidebar';
import { PaginationDesktop } from '../../../../../components-v2/pagination/index';
import Billboard from '../../../../../components/billboard/chunk-billboard.desktop';
import ViewOptionsContainer from '../../../../../components/sidebar/components/view-options/view-options.desktop';
import Header from '../../../../../components-v2/header';
import SnackBar from '../../../../../components/snackbar/container';
import FacetedSearch from '../../../../../components-v2/faceted/faceted.desktop';
import Exhibitor from '../../../../../components-v2/header/exhibitor/chunk.desktop.pi';
import Carousel from '../../../../../components-v2/carousel-search/chunk-carousel.desktop';
import { LAYOUTS } from '../../../../../constants';
import { HEADER_TYPES } from '../../../../../components-v2/header/utils/constants';
import { MAIN_VARIANTS } from './constants';
import { LayoutManager } from '../../../../../components-v2/layout/layout.manager';

const namespace = 'ui-search-main';
const sectionNamespace = 'ui-search-results';
const { TYPE_MAP } = LAYOUTS;

/**
 * View Component
 */
const getMainVariant = {
  [HEADER_TYPES.OFFICIAL_STORE]: MAIN_VARIANTS.OFFICIAL_STORE,
  [HEADER_TYPES.DEAL]: MAIN_VARIANTS.DEALS,
  [HEADER_TYPES.EXHIBITOR]: MAIN_VARIANTS.EXHIBITOR,
};

const getSectionVariationClass = (initialState) => {
  const isMap = initialState.layout_options.current === TYPE_MAP;

  return `${sectionNamespace}${isMap ? '--map' : ''}`;
};

const SearchDesktopPI = ({ baseURL, ...rest }) => {
  const { initialState } = rest;
  const headerType = initialState.header ? initialState.header.type : false;
  const mainVariant = getMainVariant[headerType] || null;

  return (
    <Search baseURL={baseURL} visualId="pi" groupSite=".pi" platformId="PIN" {...rest}>
      <Hydrator whenVisible>
        {headerType === HEADER_TYPES.DEAL || headerType === HEADER_TYPES.OFFICIAL_STORE ? <Header /> : <Exhibitor />}
      </Hydrator>
      <div className={classnames(namespace, headerType && `${namespace}--${mainVariant}`)}>
        <SideBar />
        <section className={getSectionVariationClass(initialState)}>
          <ViewOptionsContainer key={`${namespace}-sort-filter`} className={`${namespace}-pi-view-options`} />
          {headerType !== HEADER_TYPES.OFFICIAL_STORE && <FacetedSearch />}
          <Hydrator whenVisible>
            <Carousel />
          </Hydrator>
          <Hydrator whenVisible>
            <Billboard />
          </Hydrator>
          <Hydrator whenVisible>
            <LayoutManager />
          </Hydrator>
          <PaginationDesktop />
          <SnackBar />
        </section>
      </div>
    </Search>
  );
};

SearchDesktopPI.propTypes = {
  baseURL: string.isRequired,
};

/**
 * Inject i18n context as props into View.
 */
export default SearchDesktopPI;
